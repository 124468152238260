import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useCallback, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { attachDiscord } from "@/components/Auth/attachDiscord";
import { DiscordLogoSvg } from "@/components/icons";
import {
  Blockchain,
  EntityFragment,
  MutationNewEntitiesArgs,
  NewEntitiesDocument,
  NewEntityTypes,
} from "@/graphql/generated/graphql";
import { CurrentUserContext } from "@/hooks/contexts/current_user_context";

import { HatsTokenCard, NftCard } from "./NftCard";
import { TelegramEntityForm } from "./TelegramEntityForm";
import { Switch, TextField } from "../formFields";
import { Select, SelectOption } from "../formFields/Select";
import { ResponsiveFormRow } from "../formLayout/ResponsiveFormRow";
import { NewEntitySchemaType, newEntityFormSchema } from "../formValidation/entity";
import { SelectDisordServer } from "../SelectDiscordServer";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface EntityModalProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  onSubmit: (value: EntityFragment[]) => void;
  initialType: NewEntityTypes;
}

const chainOptions: SelectOption[] = [
  { name: "Ethereum", value: Blockchain.Ethereum },
  { name: "Optimism", value: Blockchain.Optimism },
  { name: "Arbitrum", value: Blockchain.Arbitrum },
  { name: "Matic", value: Blockchain.Matic },
  { name: "Base", value: Blockchain.Base },
];

const createNewAgentArgs = (data: NewEntitySchemaType): MutationNewEntitiesArgs => {
  switch (data.type) {
    case NewEntityTypes.IdentityBlockchain: {
      return {
        entities: (data.ethAddress ?? []).map((address) => ({
          identityBlockchain: {
            address: address,
          },
        })),
      };
    }
    case NewEntityTypes.IdentityEmail: {
      return {
        entities: (data.emailAddress ?? []).map((email) => ({
          identityEmail: {
            email: email,
          },
        })),
      };
    }
    case NewEntityTypes.GroupDiscord: {
      return {
        entities: data.discordRole
          ? [
              {
                groupDiscordRole: {
                  serverId: data.discordRole?.server?.id,
                  roleId: data.discordRole?.role.value,
                },
              },
            ]
          : [],
      };
    }
    case NewEntityTypes.GroupNft: {
      return {
        entities: data.nft
          ? [
              {
                groupNft: {
                  chain: data.nft.chain as Blockchain,
                  address: data.nft.contractAddress,
                  tokenId: data.nft.tokenId,
                },
              },
            ]
          : [],
      };
    }
    case NewEntityTypes.GroupHat: {
      return {
        entities: data.hat
          ? [
              {
                groupHat: {
                  chain: data.hat.chain as Blockchain,
                  tokenId: data.hat.tokenId,
                },
              },
            ]
          : [],
      };
    }
    default:
      return { entities: [] };
  }
};

export function EntityModal({ open, setOpen, onSubmit, initialType }: EntityModalProps) {
  const { me } = useContext(CurrentUserContext);

  const isConnectedToDiscord = me?.identities.find(
    (id) => id.identityType.__typename === "IdentityDiscord",
  );

  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleEntitySelection = useCallback((entities: EntityFragment[]) => {
    setOpen(false);
    onSubmit([...entities]);
  }, []);

  const [mutate] = useMutation(NewEntitiesDocument, {
    onCompleted: (data) => {
      setDisableSubmit(false);
      handleEntitySelection(data.newEntities);
    },
    onError: (error) => {
      console.log("Error", error);
      setDisableSubmit(false);
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const formMethods = useForm<NewEntitySchemaType>({
    defaultValues: {
      type: initialType,
      ethAddress: [],
      emailAddress: [],
      nft: {
        chain: Blockchain.Ethereum,
        contractAddress: "",
        tokenId: "",
        allTokens: true,
      },
      hat: {
        chain: Blockchain.Optimism,
        tokenId: "",
      },
      discordRole: {
        server: undefined,
        role: undefined,
      },
      telegramChat: undefined,
    },
    resolver: zodResolver(newEntityFormSchema),
    shouldUnregister: true,
    // shouldUnregister: false,
  });

  const { handleSubmit, watch } = formMethods;

  const createAgents = async (data: NewEntitySchemaType) => {
    setDisableSubmit(true);

    await mutate({
      variables: {
        entities: createNewAgentArgs(data).entities,
      },
    });
  };

  const inputType = watch("type");
  const nftChain = watch("nft.chain");
  const nftContractAddress = watch("nft.contractAddress");
  const nftTokenId = watch("nft.tokenId");
  const nftAllTokens = watch("nft.allTokens");
  const hatTokenId = watch("hat.tokenId");
  const hatChain = watch("hat.chain");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="role-selection-modal"
      aria-describedby="role-selection-modal"
    >
      <FormProvider {...formMethods}>
        <Box sx={style}>
          <Typography variant="h2" sx={{ mb: "16px" }}>
            Add role
          </Typography>
          <FormProvider {...formMethods}>
            <form style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Select<NewEntitySchemaType>
                name="type"
                label=""
                selectOptions={[
                  { name: "Email address", value: NewEntityTypes.IdentityEmail },
                  { name: "Eth wallet", value: NewEntityTypes.IdentityBlockchain },
                  { name: "Discord role", value: NewEntityTypes.GroupDiscord },
                  { name: "NFT", value: NewEntityTypes.GroupNft },
                  { name: "Hat", value: NewEntityTypes.GroupHat },
                  { name: "Telegram chat", value: NewEntityTypes.GroupTelegramChat },
                ]}
              />
              {inputType === NewEntityTypes.IdentityBlockchain && (
                <Box>
                  <Typography>
                    Enter an Eth address or ENS address (or multiple seperated by commas).
                  </Typography>
                  <ResponsiveFormRow
                    sx={{
                      marginTop: "8px",
                      alignItems: "center",
                      gap: "24px",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField<NewEntitySchemaType>
                      name={"ethAddress"}
                      label={"ETH wallet or ENS"}
                      showLabel={true}
                      multiline
                      variant="outlined"
                      sx={{ flexGrow: 1, minWidth: "200px" }}
                    />
                    <Button
                      onClick={handleSubmit(createAgents)}
                      variant="contained"
                      disabled={disableSubmit}
                    >
                      Submit
                    </Button>
                  </ResponsiveFormRow>
                </Box>
              )}
              {inputType === NewEntityTypes.IdentityEmail && (
                <>
                  <Typography>
                    Enter an email address (or multiple seperated by commas). The full email address
                    will not be publicly visible.
                  </Typography>

                  <ResponsiveFormRow
                    sx={{
                      marginTop: "8px",
                      alignItems: "center",
                      gap: "24px",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField<NewEntitySchemaType>
                      name={"emailAddress"}
                      label={"Email addresses"}
                      showLabel={true}
                      multiline
                      variant="outlined"
                      sx={{ flexGrow: 1, minWidth: "200px" }}
                    />
                    <Button
                      type="submit"
                      onClick={handleSubmit(createAgents)}
                      variant="contained"
                      disabled={disableSubmit}
                    >
                      Submit
                    </Button>
                  </ResponsiveFormRow>
                </>
              )}
              {inputType === NewEntityTypes.GroupDiscord && (
                <>
                  {!isConnectedToDiscord ? (
                    <>
                      <Typography>
                        Connect Discord to Ize to attach Discord roles to this process.
                      </Typography>
                      <Button
                        onClick={attachDiscord}
                        variant={"outlined"}
                        sx={{ width: "200px" }}
                        startIcon={<DiscordLogoSvg />}
                      >
                        Connect Discord
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          gap: "16px",
                          alignItems: "flex-end",
                        }}
                      >
                        <SelectDisordServer<NewEntitySchemaType> name={"discordRole"} />
                        <Button
                          type="submit"
                          onClick={handleSubmit(createAgents)}
                          variant="contained"
                          disabled={disableSubmit}
                        >
                          Submit
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
              {inputType === NewEntityTypes.GroupNft && (
                <>
                  <div>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        alignItems: "left",
                      }}
                    >
                      <ResponsiveFormRow>
                        <Select<NewEntitySchemaType>
                          name="nft.chain"
                          label="Chain"
                          selectOptions={chainOptions}
                        />
                        <TextField<NewEntitySchemaType>
                          name={"nft.contractAddress"}
                          placeholderText="NFT contract address"
                          label="Contract Address"
                          variant="outlined"
                          sx={{ flexGrow: 1, minWidth: "300px" }}
                        />
                      </ResponsiveFormRow>
                      <ResponsiveFormRow
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Switch<NewEntitySchemaType>
                          name={"nft.allTokens"}
                          label="Include all token Ids"
                          sx={{ flexGrow: 1 }}
                        />
                        {!nftAllTokens && (
                          <TextField<NewEntitySchemaType>
                            name={"nft.tokenId"}
                            placeholderText="Token Id"
                            label="Token Id"
                            variant="outlined"
                            sx={{ flexGrow: 1, minWidth: "100px" }}
                          />
                        )}
                      </ResponsiveFormRow>
                      <NftCard address={nftContractAddress} tokenId={nftTokenId} chain={nftChain} />
                      <Button
                        onClick={handleSubmit(createAgents)}
                        variant="contained"
                        disabled={disableSubmit}
                        sx={{ width: "200px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </div>
                </>
              )}
              {inputType === NewEntityTypes.GroupHat && (
                <>
                  <ResponsiveFormRow
                    sx={{
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Select<NewEntitySchemaType>
                      name="hat.chain"
                      label="Chain"
                      selectOptions={chainOptions}
                    />
                    <TextField<NewEntitySchemaType>
                      name={"hat.tokenId"}
                      placeholderText="Hat ID (hex or decimal)"
                      label={"Hat Token Id"}
                      multiline={false}
                      variant="outlined"
                      sx={{ flexGrow: 1, minWidth: "200px" }}
                    />
                  </ResponsiveFormRow>
                  <HatsTokenCard chain={hatChain} tokenId={hatTokenId} />
                  <Button
                    onClick={handleSubmit(createAgents)}
                    variant="contained"
                    disabled={disableSubmit}
                    sx={{ width: "200px" }}
                  >
                    Submit
                  </Button>
                </>
              )}
              {inputType === NewEntityTypes.GroupTelegramChat && (
                <TelegramEntityForm handleEntitySelection={handleEntitySelection} />
              )}
            </form>
          </FormProvider>
        </Box>
      </FormProvider>
    </Modal>
  );
}
