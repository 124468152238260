import { TableCellProps, styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";

interface TableCellHideableProps extends TableCellProps {
  hideOnSmallScreen?: boolean;
}

export const HiddenCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const TableCellHideable = ({
  hideOnSmallScreen,
  ...props
}: TableCellHideableProps): JSX.Element => {
  return hideOnSmallScreen ? (
    <HiddenCell {...props}>{props.children}</HiddenCell>
  ) : (
    <TableCell {...props}>{props.children}</TableCell>
  );
};
