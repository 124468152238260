import { Box, FormHelperText } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";

import { ActionFilterForm } from "./ActionFilterForm";
import { PermissionForm } from "./PermissionForm";
import { ResultsForm } from "./ResultForm/ResultsForm";
import { PanelAccordion } from "../../../ConfigDiagram/ConfigPanel/PanelAccordion";
import { Select, Switch } from "../../formFields";
import { FlowSchemaType } from "../formValidation/flow";

interface StepFormProps {
  stepIndex: number; // react-hook-form name
  show: boolean;
  reusable: boolean;
}

const requestExpirationOptions = [
  { name: "1 hour", value: 3600 },
  { name: "4 hours", value: 14400 },
  { name: "1 day", value: 86400 },
  { name: "3 days", value: 259200 },
  { name: "7 days", value: 604800 },
  { name: "30 days", value: 2592000 },
];

export const StepForm = ({ stepIndex, show, reusable }: StepFormProps) => {
  const { formState, control, getValues } = useFormContext<FlowSchemaType>();
  const stepError = formState.errors.steps?.[stepIndex];

  const fieldsArrayMethods = useFieldArray({
    control: control,
    name: `steps.${stepIndex}.fieldSet.fields`,
  });

  const fields = getValues(`steps.${stepIndex}.fieldSet.fields`);

const allInternalFields = fields.every((field) => field.isInternal);

  return (
    <Box sx={{ display: show ? "box" : "none" }}>
      {stepError?.root && (
        <FormHelperText
          sx={{
            color: "error.main",
            marginLeft: "16px",
          }}
        >
          {stepError?.root.message}
        </FormHelperText>
      )}
      {stepIndex > 0 && (
        <ActionFilterForm
          stepIndex={stepIndex - 1}
          action={getValues(`steps.${stepIndex - 1}.action`)}
          isTriggerAction={true}
        />
      )}
      {!allInternalFields && (
        <PanelAccordion
          title="Permissions"
          hasError={!!formState.errors.steps?.[stepIndex]?.response}
        >
          <PermissionForm<FlowSchemaType>
            fieldName={`steps.${stepIndex}.response.permission`}
            branch={"response"}
          />
          <Select<FlowSchemaType>
            label="How long do people have to respond?"
            renderValue={(val) => {
              const option = requestExpirationOptions.find((option) => option.value === val);
              return option?.name + " to respond";
            }}
            selectOptions={requestExpirationOptions}
            name={`steps.${stepIndex}.response.expirationSeconds`}
            size={"small"}
          />
          <Switch<FlowSchemaType>
            name={`steps.${stepIndex}.response.allowMultipleResponses`}
            label="Allow multiple responses"
          />
          <Switch<FlowSchemaType>
            name={`steps.${stepIndex}.response.canBeManuallyEnded`}
            label="Allow triggerer to end step early"
          />
        </PanelAccordion>
      )}
      <PanelAccordion
        title="Collaborations"
        sx={{ paddingBottom: "40px" }}
        hasError={
          !!formState.errors.steps?.[stepIndex]?.fieldSet ||
          !!formState.errors.steps?.[stepIndex]?.result
        }
      >
        <ResultsForm
          reusable={reusable}
          stepIndex={stepIndex}
          //@ts-expect-error TODO
          fieldsArrayMethods={fieldsArrayMethods}
        />
      </PanelAccordion>
    </Box>
  );
};
