import { Box, Tabs as MuiTabs, SxProps, Tab } from "@mui/material";
import * as React from "react";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface TabProps {
  title: string;
  content: JSX.Element | null;
  icon?: React.ReactElement;
}

export interface TabsProps {
  tabs: TabProps[];
  currentTabIndex: number;
  handleChange: (_event: React.SyntheticEvent, newValue: number) => void;
  sx?: SxProps;
}

export const Tabs = ({ tabs, currentTabIndex, handleChange, sx = {} }: TabsProps): JSX.Element => {
  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs value={currentTabIndex} onChange={handleChange} aria-label="tabs">
          {tabs.map((tab: TabProps, index) => {
            return (
              <Tab
                label={tab.title}
                key={index}
                {...a11yProps(index)}
                icon={tab.icon}
                iconPosition="end"
                sx={{ minHeight: "40px" }}
              />
            );
          })}
        </MuiTabs>
      </Box>
    </Box>
  );
};
